






























import { Component, Prop, Vue } from "vue-property-decorator";
import { HistoryChange } from "@/entity/History";
import { State } from "vuex-class";
import User from "@/entity/User";
import UserService from "@/services/UserService";
import { cloneDeep } from "lodash";
import ContactsService from "@/services/ContactsService";
import { capitalizeStart, formatPrice } from "@/utils/string";
import { ReferenceItem } from "@/services/types";

@Component
export default class CompareChangesDialog extends Vue {
  @Prop({ required: true, type: Array }) value!: HistoryChange[];

  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];
  @State("industries", { namespace: "selectOptions" })
  industries!: ReferenceItem[];

  isLoading = true;
  mappedValues: HistoryChange[] = [];

  async getMappedValues(): Promise<HistoryChange[]> {
    const value = cloneDeep(this.value);
    return Promise.all(
      value.map((change) => {
        switch (change.key) {
          case "regionId": {
            return this.mapRegionId(change);
          }
          case "contactBranchIds": {
            return this.mapContactBranchIds(change);
          }
          case "consultantId": {
            return this.mapConsultantId(change);
          }
          case "consultantSecondId": {
            return this.mapConsultantId(change);
          }
          case "status": {
            return this.mapProjectStatus(change);
          }
          case "potentialWin":
          case "initialCost":
          case "marketingBudget":
          case "salesPricePublished": {
            return this.formatNumber(change);
          }
          case "ownerContactId": {
            return this.mapOwnerId(change);
          }
          default: {
            return change;
          }
        }
      })
    );
  }

  mapChangeKeys(): HistoryChange[] {
    const pairs: { [key: string]: string } = {
      firstName: "name",
      consultantId: "firstConsultant",
      consultantSecondId: "secondConsultant",
      revenue: "turnover",
      potentialWin: "potentialFee",
      marketingBudget: "advertisingBudget",
      startingDate: "startDate",
      contactBranchIds: "industry",
      regionId: "region",
      ownerId: "owner",
      mail: "email",
      url: "website",
    };

    return this.mappedValues.map((change) => ({
      ...change,
      key: pairs[change.key] || change.key,
    }));
  }

  mapRegionId(change: HistoryChange): HistoryChange {
    if (typeof change.previousState === "number") {
      change.previousState = (
        this.regions.find(
          (region) => region.id === change.previousState
        ) as ReferenceItem
      ).name;
    }

    if (typeof change.nextState === "number") {
      change.nextState = (
        this.regions.find(
          (region) => region.id === change.nextState
        ) as ReferenceItem
      ).name;
    }

    return change;
  }

  mapContactBranchIds(change: HistoryChange): HistoryChange {
    if (typeof change.previousState === "string") {
      const ids = change.previousState.split(",").map((id) => parseInt(id));
      change.previousState = (
        this.industries.filter((industry) =>
          ids.includes(industry.id)
        ) as ReferenceItem[]
      )
        .map((industry) => industry.name)
        .join(", ");
    }

    if (typeof change.nextState === "string") {
      const ids = change.nextState.split(",").map((id) => parseInt(id));
      change.nextState = (
        this.industries.filter((industry) =>
          ids.includes(industry.id)
        ) as ReferenceItem[]
      )
        .map((industry) => industry.name)
        .join(", ");
    }

    return change;
  }

  async mapConsultantId(change: HistoryChange): Promise<HistoryChange> {
    const users = await UserService.findAll();

    if (typeof change.previousState === "number") {
      change.previousState = (
        users.find((user) => user.id === change.previousState) as User
      ).displayName;
    }

    if (typeof change.nextState === "number") {
      change.nextState = (
        users.find((user) => user.id === change.nextState) as User
      ).displayName;
    }

    return change;
  }

  mapProjectStatus(change: HistoryChange): HistoryChange {
    if (typeof change.previousState === "string") {
      change.previousState = capitalizeStart(this.$tc(change.previousState, 1));
    }

    if (typeof change.nextState === "string") {
      change.nextState = capitalizeStart(this.$tc(change.nextState, 1));
    }

    return change;
  }

  async mapOwnerId(change: HistoryChange): Promise<HistoryChange> {
    if (typeof change.previousState === "number") {
      const previousOwner = await ContactsService.findOne(change.previousState);
      change.previousState = previousOwner.fullName;
    }

    if (typeof change.nextState === "number") {
      const nextOwner = await ContactsService.findOne(change.nextState);
      change.nextState = nextOwner.fullName;
    }

    return change;
  }

  formatNumber(change: HistoryChange): HistoryChange {
    if (typeof change.previousState === "number") {
      change.previousState = formatPrice(change.previousState);
    }

    if (typeof change.nextState === "number") {
      change.nextState = formatPrice(change.nextState);
    }

    return change;
  }

  async created(): Promise<void> {
    this.mappedValues = await this.getMappedValues();
    this.mappedValues = this.mapChangeKeys();
    this.isLoading = false;
  }
}
