














































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { ITableHeader } from "@/components/data-table/types";
import DataTable from "@/components/data-table/DataTable.vue";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import User from "@/entity/User";
import Contact, { getContactFullName } from "@/entity/Contact";
import Tag from "@/entity/Tag";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import HasAttachmentCell from "@/components/HasAttachmentCell.vue";
import History, { HistoryTypes, tagIdsToNames } from "@/entity/History";
import { contactHistoryNavigationTo } from "@/utils/contactHistoryNavigationTo";

@Component({
  components: {
    DataTable,
    ActionButtonWithTooltip,
    HasAttachmentCell,
  },
})
export default class ProjectHistoryDataTable extends Mixins(
  TranslatedEnumListsMixin
) {
  @Prop({ type: Array, required: true }) history!: History[];
  @Prop({ type: Array, required: true }) authorOptions!: User[];
  @Prop({ type: Array, required: true }) interestedOptions!: Contact[];
  @Prop({ type: Array, required: true }) tags!: Tag[];
  @Prop({ type: Number, required: true }) totalItems!: number;
  @Prop({ type: Boolean, required: true }) isLoading!: boolean;
  @Prop({ type: Function, required: true }) onOptionsChange!: () => void;
  @Prop({ type: Function, required: true }) addItemClicked!: () => void;
  @Prop({ type: Function, required: true }) onRowClick!: () => void;
  @Prop({ type: Function, required: true }) compareChanges!: () => void;
  @Prop({ type: Function, required: true }) deleteHistory!: () => void;

  manualHistoryType = HistoryTypes.MANUAL;
  tagIdsToNames = tagIdsToNames;
  contactHistoryNavigationTo = contactHistoryNavigationTo;
  contactFullName = getContactFullName;

  get headers(): ITableHeader[] {
    return [
      {
        text: this.$tc("date", 1),
        value: "historyDate",
        filterType: "date",
        width: "150px",
      },
      {
        text: this.$tc("interested", 1),
        value: "contact.firstName",
        filterValue: "contactId",
        filterType: "select",
        options: this.interestedOptions,
        optionText: "_displayName",
        optionValue: "id",
        width: "200px",
      },
      {
        text: this.$tc("note", 1),
        value: "note",
        filterType: "text",
        width: "300px",
      },
      {
        text: this.$tc("author", 1),
        value: "creator.displayName",
        filterValue: "creatorId",
        options: this.authorOptions,
        optionText: "displayName",
        optionValue: "id",
        filterType: "select",
        width: "200px",
      },
      {
        text: this.$tc("tag", 2),
        value: "tags",
        options: this.tags,
        optionText: "name",
        optionValue: "id",
        filterType: "select",
        multiselect: true,
        width: "150px",
      },
      {
        text: this.$tc("type", 1),
        value: "eventType",
        filterType: "select",
        options: this.translatedEnums.historyTypes,
        optionText: "name",
        optionValue: "value",
        sortable: false,
        width: "150px",
      },
      {
        text: this.$tc("attachment", 1),
        value: "hasAttachments",
        filterType: "select",
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        sortable: true,
        width: "150px",
      },
      {
        text: this.$tc("actions"),
        value: "actions",
        sortable: false,
        width: "80px",
      },
    ];
  }

  getAuthorName(item: History): string {
    if (item.eventType !== HistoryTypes.MANUAL && !item.creatorId)
      return this.$tc("autoGenerated");
    return item.creator ? item.creator.displayName : "";
  }

  hasChanges(history: History): boolean {
    const meta = history.meta;
    if (!meta) {
      return false;
    }
    const json = JSON.parse(meta);
    return Object.prototype.hasOwnProperty.call(json, "change");
  }

  isHistoryEoi(item: History): boolean {
    if (
      item.eventType === HistoryTypes.EXPRESSION_OF_INTEREST_FILLED ||
      item.eventType === HistoryTypes.EXPRESSION_OF_INTEREST_CHANGED
    )
      return true;
    return false;
  }
}
