import Contact, { ContactTypeId } from "@/entity/Contact";
import { Location } from "vue-router";

export default (contact: Contact): Location | null => {
  if (contact === undefined || contact.id === undefined) {
    return null;
  }

  if (contact.id && contact.contactTypeId === ContactTypeId.company) {
    return {
      name: "companyHistory",
      params: {
        companyId: contact.id.toString(),
      },
    };
  } else if (contact.id && contact.contactTypeId === ContactTypeId.contact) {
    return {
      name: "contactHistory",
      params: {
        contactId: contact.id.toString(),
      },
    };
  }

  return null;
};

export const contactHistoryNavigationTo = (
  contact: Contact
): Location | null => {
  if (!contact || !contact.id) {
    return null;
  }

  return {
    name: "contactHistory",
    params: {
      contactId: contact.id.toString(),
    },
  };
};
