












































import { Component, Mixins, Prop } from "vue-property-decorator";
import DataTable from "@/components/data-table/DataTable.vue";
import HistoryService from "@/services/HistoryService";
import { TableQuery } from "@/components/data-table/types";
import HistoryForm from "@/components/history-form/HistoryForm.vue";
import History, { HistoryChange } from "@/entity/History";
import Contact from "@/entity/Contact";
import DialogMixin from "@/mixins/DialogMixin";
import User from "@/entity/User";
import UserService from "@/services/UserService";
import CompareChangesDialog from "@/components/CompareChangesDialog.vue";
import Project from "@/entity/Project";
import Tag from "@/entity/Tag";
import TagService from "@/services/TagService";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import { getFirstQueryValueAsInt } from "@/utils/queryParams";
import ProjectHistoryDataTable from "@/components/data-tables/ProjectHistoryDataTable.vue";

@Component({
  components: {
    DataTable,
    HistoryForm,
    CompareChangesDialog,
    ProjectHistoryDataTable,
  },
})
export default class ProjectHistoryView extends Mixins(
  DialogMixin,
  TranslatedEnumListsMixin
) {
  @Prop({ required: true }) project!: Project;

  history: History[] = [];
  interestedOptions: Contact[] = [];
  authorOptions: User[] = [];
  tags: Tag[] = [];
  showSkeleton = true;
  isHistoryLoading = false;
  itemsLength = 0;
  options!: TableQuery;
  changesToCompare: HistoryChange[] = [];

  get historyId() {
    return getFirstQueryValueAsInt(this.$route.params.historyId);
  }

  async fetchHistory(): Promise<void> {
    try {
      this.isHistoryLoading = true;

      this.tags = (await TagService.find()).content;
      const options = Object.assign({}, this.options, {
        filterBy: {
          ...this.options.filterBy,
          projectId: this.project.id,
        },
      });

      const response = await HistoryService.find(options);
      this.history = response.content;
      this.itemsLength = response.totalItems;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isHistoryLoading = false;
    }
  }

  async deleteHistory(historyId: number): Promise<void> {
    try {
      this.isHistoryLoading = true;
      const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));

      if (didConfirm) {
        await HistoryService.delete(historyId);
        await this.fetchHistory();
      }
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isHistoryLoading = false;
    }
  }

  compareChanges(item: History): void {
    this.changesToCompare = JSON.parse(item.meta as string).change;
    this.openDialog("compareChanges");
  }

  onHistoryOpen(history: History) {
    this.$router.replace({
      name: "projectHistoryDetail",
      params: {
        historyId: (history.id as number).toString(),
      },
    });
    this.openDialog("historyForm", history.id);
  }

  onHistoryCancel() {
    this.$router.replace({
      name: "projectHistory",
    });
    this.closeDialog();
  }

  onHistoryFormSuccess() {
    this.$router.replace({
      name: "projectHistory",
    });
    this.closeDialog();
    this.fetchHistory();
  }

  onOptionsChange(options: TableQuery) {
    this.options = options;
    this.fetchHistory();
  }

  async created() {
    if (this.historyId) {
      this.openDialog("historyForm", this.historyId);
    }

    [this.authorOptions, this.interestedOptions] = await Promise.all([
      UserService.findAll(),
      HistoryService.contactNames({
        filterBy: {
          projectId: this.project.id,
        },
      }),
    ]);
    this.showSkeleton = false;
  }
}
