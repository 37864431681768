var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{key:"table",attrs:{"items":_vm.history,"headers":_vm.headers,"loading":_vm.isLoading,"items-length":_vm.totalItems,"has-add-button":true,"clickable":true,"name":"historyOverview"},on:{"addItemClicked":_vm.addItemClicked,"optionsChanged":_vm.onOptionsChange,"rowClicked":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.historyDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatUnixTimestamp")(item.historyDate))+" ")]}},{key:"item.contact.firstName",fn:function(ref){
var item = ref.item;
return [(item.contact && item.contact.firstName)?_c('router-link',{attrs:{"to":_vm.contactHistoryNavigationTo(item.contact)}},[_vm._v(_vm._s(item.contact.firstName + " " + item.contact.lastName))]):_vm._e()]}},{key:"item.creator.displayName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAuthorName(item))+" ")]}},{key:"item.eventType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("historyTypes." + (item.eventType))))+" ")]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.tagIdsToNames(_vm.tags, item.tags)),function(tag){return _c('v-chip',{key:tag,staticClass:"mb-2",staticStyle:{"margin":"4px 8px 4px 0"},domProps:{"textContent":_vm._s(tag)}})})}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("shortenText")(item.note)))])]}}],null,true)},[_vm._v(" "+_vm._s(item.note)+" ")])]}},{key:"item.hasAttachments",fn:function(ref){
var item = ref.item;
return [_c('HasAttachmentCell',{attrs:{"has-attachments":item.hasAttachments || _vm.isHistoryEoi(item)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.eventType === _vm.manualHistoryType)?_c('ActionButtonWithTooltip',{attrs:{"icon":"mdi-delete","has-gap":true,"tooltip":_vm.$tc('delete', 1)},on:{"click":function($event){return _vm.deleteHistory(item.id)}}}):_vm._e(),(_vm.hasChanges(item))?_c('ActionButtonWithTooltip',{attrs:{"icon":"mdi-file-compare","tooltip":_vm.$tc('compare', 1)},on:{"click":function($event){return _vm.compareChanges(item)}}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }